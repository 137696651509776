<template>
    <div>

        <v-row>
            <v-col cols="12" md="6">
                <v-row align="left" justify="start">
                    <v-col cols="auto" class="mr-auto">
                        <strong>ID: </strong>{{ item.id }}
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="auto" class="mr-auto">
                        <strong>Carregador: </strong>{{ item.charger }}
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="auto" class="mr-auto">
                        <strong>Plug: </strong>{{ item.plug }}
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12" md="6">
                <v-row>
                    <v-col cols="auto" class="mr-auto">
                        <strong>Inicio: </strong>{{ $moment(item.dateStart).format('DD/MM/YYYY hh:mm') }}
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="auto" class="mr-auto">
                        <strong>Fim: </strong>{{ $moment(item.dateFinish).format('DD/MM/YYYY hh:mm') }}
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="auto" class="mr-auto">
                <strong>Consumo: </strong>{{ item.consumption ? Number.parseFloat(item.consumption).toFixed(2) : 0 }} kWh
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="auto" class="mr-auto">
                <strong>Valor: </strong> R$ {{ item.value ? Number.parseFloat(item.value).toFixed(2) : 0 }}
            </v-col>
        </v-row>
    </div>
</template>

<script>

import receipt from "@/pdf/receipt";
// 
export default {

    props: {
        item: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            receipt,
        };
    },

    methods: {
        createPDF() {
            let doc = receipt.create(this.item);
            // doc.output("report.pdf");
            window.open(doc.output("bloburl"));
        },
    },
}
</script>